* {
    box-sizing: border-box;
}

.div-fan {
    text-align: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    height: auto;
    background: linear-gradient(41.36deg, #4781FF 2.85%, #35CBCC 97.24%);
    box-shadow: 0px 15px 25px rgba(71, 129, 255, 0.2);
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 2px;
    margin: auto;
}

.div-fanatico {
    text-align: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    height: auto;
    background: linear-gradient(41.36deg, #F64E4E 2.85%, #FFBE06 97.24%);
    box-shadow: 0px 15px 25px rgba(246, 78, 78, 0.2);
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 2px;
    margin: auto;
}

.div-superfanatico {
    text-align: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    height: auto;
    background: linear-gradient(239.42deg, #D3168F 6.36%, #8234CF 91.16%);
    box-shadow: 0px 15px 25px rgba(212, 25, 129, 0.2);
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 2px;
    margin: auto;
}

.qr-title {
    margin-top: -50px;
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
    text-align: center;
    color: #08285B;
}

.div-description {
    max-width: 350px;
    width: 100%;
    text-align: center;
    justify-items: center;
    margin: auto;
    position: relative;
}

.qr-description {
    font-family: 'Montserrat';
    font-style: normal;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #08285B;
    display: inline-block;
    white-space: pre-line;
}

.qr-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: -15px;
    margin-bottom: 0px;
    text-align: center;
    letter-spacing: 4px;
    color: #FFFFFF;
}

.qr-level {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 30px;
    color: #FFFFFF;
}

.qr-div {
    width: 60%;
    border-radius: 10px;
    padding: 15px 5px 15px 5px;
    margin: -5% 20%;
    height: auto;
    background-color: white;
}

.qr-card {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.5);
    padding: 15px 0px 0px 0px;
}

.div-qr{
    margin-bottom: 40px;
}

.prev-white {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-align: center;
    width: 100%;
    height: 45px;
    margin-top: 20px;
    margin-left: 0;
    color: #3e2669;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: 0.5s ease;
    border-radius: 10px;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #3e2669, #3e2669) border-box;
    border: 1px solid transparent;
}

.prev {
    text-align: center;
    border-color: #3db9ac;
    width: 100%;
    height: 50px;
    margin-top: 0px;
    margin-left: 0px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: 0.5s ease;
    border: 0px;
    border-radius: 10px;
    background: #3db9ac;
}


.prev-purple {
    text-align: left;
    border-color: #522d6d;
    width: 100%;
    height: 50px;
    margin-top: 0px;
    margin-left: 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: 0.5s ease;
    border: 0px;
    border-radius: 10px;
    background: #522d6d;
    padding-left: 50px;
}




@media (max-width: 575px) {

    .text-center {
        width: 90%;
        margin-left: 10%;
        text-align: center;
        justify-content: center;
    }

    .prev-white {
        width: 100%;
        margin: 0px;
    }

    .prev {
        width: 100%;
        margin: 0px;
    }
}

@media (max-width: 574px) {

    .text-center {
        width: 90%;
        margin-left: 5%;
        text-align: center;
        justify-content: center;
    }

    .prev-white {
        width: 100%;
        margin: 0px;
    }

    .prev {
        width: 100%;
        margin: 0px;
    }
}


@media (max-width: 501px) {

    .text-center {
        width: 90%;
        margin-left: 5%;
        text-align: center;
        justify-content: center;
    }

    .prev-white {
        min-width: 100%;
        width: 100%;
        margin: 0px;
    }

    .prev {
        min-width: 100%;
        width: 100%;
        margin: 0px;
    }
}