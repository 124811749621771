* {
    box-sizing: border-box;
}

.cards {
    max-width: 750px;
    width: 100%;
    margin: 5% auto;
    display: grid;
    gap: 1rem;
    text-align: center;
    justify-items: center;
}

.card {
    color: white;
    padding: 1rem;
    text-align: center;
    justify-items: center;
}


.gray {
    max-width: 500px;
    width: 100%;
    height: auto;
    left: 272px;
    top: 202px;
    background: #F3F4F7;
    border-radius: 20px;
}

.logo-club:not([src]) {
    margin-top: 15px;
    width: 45%;
    background-image: url('../../assets/emoji-surprised.png');
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 70% 0 0 0;
    height: auto;
}

.warning {
    position: inherit;
    width: 100%;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #08285B;
    margin-top: -40px;
}

.title {
    left: 55.9%;
    right: 19.51%;
    top: 22.95%;
    width: 100%;
    bottom: 70.64%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
    align-items: center;
    text-align: center;
    color: #08285B;
}

.title-small {
    left: 55.9%;
    right: 19.51%;
    top: 22.95%;
    width: 100%;
    bottom: 70.64%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    color: #08285B;
}

.subtitle {
    left: 55.9%;
    right: 19.51%;
    top: 22.95%;
    width: 100%;
    bottom: 70.64%;
    font-family: 'Montserrat';
    font-style: bold;
    font-weight: 800;
    font-size: 15px;
    line-height: 42px;
    align-items: center;
    text-align: center;
    color: #08285B;
}

.description {
    left: 55.9%;
    right: 19.51%;
    top: 33.31%;
    bottom: 57.71%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #08285B;
}

.description-left {
    left: 55.9%;
    right: 19.51%;
    top: 33.31%;
    bottom: 57.71%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #08285B;
}

/* Screen larger than 600px? 2 column */
@media (max-width: 769px) {
    .cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .card {
        height: 100%;
        margin-top: 0;
        background: #fff;
    }

    .contenido {
        margin-top: -100px;
        background: #fff;
        padding-top: 0px;
        height: 600px;

    }

    .logo-club:not([src]) {
        margin-top: 15px;
        width: 40%;
        background-image: url('../../assets/logo-end.png');
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 70% 0 0 0;
        height: auto;
    }

    .warning {
        margin-top: -40%;
    }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 770px) {
    .cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .gray {
        background: #F3F4F7;
    }

    #navbar_menubar_first{
        display: none;
    }
}
