.navbar {
    width: 100%;
    background: #3e2669;
    height: 160px;
    margin-top: -10px;
}


.logo:not([src]) {
    margin-top: 50px;
    width: 150px;
    margin-left: 10%;
    background-image: url('https://pm.idportal.app/invex/img/logo-techreo-blanco.png');
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 4rem 0 0 0;
    height: 40px;
    border: 0px;
}

.navbar_menubar {
    box-sizing: border-box;
    margin: 25px auto;
    text-align: center;
    top: 50%;
}

.navbar_menubar_first {
    list-style: none;
    display: inline-block;
    cursor: pointer;
    color: #3e2669;
}

.navbar_menubar_middle {
    list-style: none;
    display: inline-block;
    padding: 0 10%;
    color: #3e2669;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    align-items: center;
    text-align: center;
}

.navbar_menubar_last {
    list-style: none;
    display: inline-block;
    cursor: pointer;
    color: #3e2669;
}

.hr_green {
    height: 5px;
    width: 8%;
    display: inline-flex;
    background: #4781FF;
    border-radius: 50px;
    margin: 0 5px;
}

.hr_gray {
    height: 5px;
    width: 8%;
    display: inline-flex;
    background: #a7a7a7;
    border-radius: 50px;
    margin: 0 5px;
}

@media (max-width: 750px) {
    .navbar {
        width: 100%;
        background: #3e2669;
        height: 160px;
        text-align: center;
    }

    .navbar_menubar_middle {
        list-style: none;
        display: inline-block;
        padding: 0 30%;
    }

    .hr_green {
        height: 5px;
        width: 20%;
        display: inline-flex;
        background: #4781FF;
        border-radius: 50px;
        margin: 0 5px;
    }

    .hr_gray {
        height: 5px;
        width: 20%;
        display: inline-flex;
        background: #a7a7a7;
        border-radius: 50px;
        margin: 0 5px;
    }

    .logo:not([src]) {
        margin-top: 50px;
        margin-left: auto;
        width: 150px;
        background-image: url('https://pm.idportal.app/invex/img/logo-techreo-blanco.png');
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 5rem 0 0 0;
        height: 12px;
    }



}

@media (max-width: 575.98px) {

    .navbar {
        width: 100%;
        background: #3e2669;
        height: 160px;
        text-align: center;
    }

    .hr_green {
        height: 5px;
        width: 25%;
        display: inline-flex;
        background: #4781FF;
        border-radius: 50px;
        margin: 0 5px;
    }

    .hr_gray {
        height: 5px;
        width: 25%;
        display: inline-flex;
        background: #a7a7a7;
        border-radius: 50px;
        margin: 0 5px;
    }

    .navbar_menubar_middle {
        list-style: none;
        display: inline-block;
        padding: 0 20%;
    }

    .logo:not([src]) {
        margin-top: 50px;
        margin-left: auto;
        width: 150px;
        background-image: url('https://pm.idportal.app/invex/img/logo-techreo-blanco.png');
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 5rem 0 0 0;
        height: 12px;
    }

    @media (max-width: 355px) {
        .navbar {
            width: 100%;
            background: #3e2669;
            height: 160px;
            text-align: center;
        }

        .navbar_menubar_middle {
            list-style: none;
            display: inline-block;
            padding: 0 15%;
        }

        .hr_green {
            height: 5px;
            width: 20%;
            display: inline-flex;
            background: #4781FF;
            border-radius: 50px;
            margin: 0 5px;
        }

        .hr_gray {
            height: 5px;
            width: 20%;
            display: inline-flex;
            background: #a7a7a7;
            border-radius: 50px;
            margin: 0 5px;
        }

        .logo:not([src]) {
            margin-top: 50px;
            margin-left: auto;
            width: 150px;
            background-image: url('https://pm.idportal.app/invex/img/logo-techreo-blanco.png');
            background-repeat: no-repeat;
            background-size: 100%;
            padding: 5rem 0 0 0;
            height: 12px;
        }

    }
}