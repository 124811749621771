.modal-terms {
    width: 80%;
    height: 90%;
    position: fixed;
    top: 8%;
    left: 10%;
    background: rgba(255, 255, 255);
    padding: 0px;
    display: flex;
    place-items: center;
    justify-content: center;
    font-family: 'Roboto';
}

.modal-contenido-terms {
    width: 100%;
    min-height: 100%;
    background: #fff;
    position: relative;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px
}

.contenido-terms{
    width: 100%;
    height: 80vh;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.modal-header h3 {
    font-weight: 500;
    font-size: 14px;
}

.modal-header h5 {
    font-weight: 500;
    font-size: 12px;
}

.modal-button-terms {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 20px;
    height: 20px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
}

.modal-button-terms:hover {
    background: #f2f2f2;
}
.modal-item{
    font-size: .80em;
    font-weight: 300;
    margin-bottom: 15px;
    text-align: justify;
    font-family: 'Roboto';
}

@media (max-width: 575.98px) {

    * {
        box-sizing: border-box;
    }

    .modal-terms {
        width: 95%;
        height: 95%;
        position: fixed;
        top: 2%;
        left: 2.5%;
        background: rgba(255, 255, 255);
        padding: 0px;
        display: flex;
        place-items: center;
        justify-content: center;
        z-index: 10;
    }

    .modal-button-terms {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border: none;
        background: none;
        cursor: pointer;
        transition: .3s ease all;
        border-radius: 5px;
    }
}