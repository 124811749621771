* {
  box-sizing: border-box;
}

.fondo-loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #3e2669;
  z-index: 99999;
  opacity: 1;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.loader {
  width: 60px;
}

.loader-wheel {

  width: 50%;
  height: 50%;
  border-radius: 100%;
  margin: auto auto;
  border: 1.1em solid rgba(255, 255, 255, .2);
  border-left-color: #a0d61c;
  -webkit-animation: load 1.1s infinite linear;
  /* Chrome, Safari, Opera */
  animation: load 1.1s infinite linear;
}

@-webkit-keyframes load {
  0% {
    -ms-transform: rotate(7deg);
    /* IE 9 */
    -webkit-transform: rotate(7deg);
    /* Chrome, Safari, Opera */
    transform: rotate(7deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    /* IE 9 */
    -webkit-transform: rotate(360deg);
    /* Chrome, Safari, Opera */
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -ms-transform: rotate(7deg);
    /* IE 9 */
    -webkit-transform: rotate(7deg);
    /* Chrome, Safari, Opera */
    transform: rotate(7deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    /* IE 9 */
    -webkit-transform: rotate(360deg);
    /* Chrome, Safari, Opera */
    transform: rotate(360deg);
  }


}


@keyframes zoom {
  0% {
    width: 50px;
    height: 50px;
    border-color: rgba(118, 66, 55, 1);
  }

  25% {
    width: 70px;
    height: 70px;
    border-color: rgba(118, 66, 55, 0.8);
  }

  50% {
    width: 90px;
    height: 90px;
    border-color: rgba(118, 66, 55, 0.6);
  }

  75% {
    width: 110px;
    height: 110px;
    border-color: rgba(118, 66, 55, 0.5);
  }

  100% {
    width: 130px;
    height: 130px;
    border-color: rgba(118, 66, 55, 0.4);
  }
}


.spinner {
  position: absolute;
  display: grid;
  place-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.container {
  margin-top: 0px;
}