* {
    box-sizing: border-box;
}


h5 {
    width: 100%;
    top: 200px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #3e2669;
}

.btn-blue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-color: #a0d61c;
    width: 90%;
    height: 45px;
    margin-top: 20px;
    margin-left: 5%;
    color: #FFFFFF;
    cursor: pointer;
    transition: 0.5s ease;
    border: 0px;
    border-radius: 30px;
    background: #a0d61c;
    z-index: 7;
}

.btn-white {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 90%;
    height: 45px;
    margin-left: 5%;
    cursor: pointer;
    transition: 0.5s ease;
    border-radius: 30px;
    color: #08285B;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #a0d61c, #35CBCC) border-box;
    border-radius: 100px;
    border: 1px solid transparent;
    z-index: 7;
}


@media (max-width: 575.98px) {

    * {
        box-sizing: border-box;
    }

    form {
        width: 100%;
    }

    .content {
        width: 100%;
        padding: 12px 15px 20px 15px;
        margin-top: 0px;
    }

    form input {
        height: 40px;
    }

    form select {
        height: 40px;
    }
}