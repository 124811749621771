.checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 25px;
    margin: 0;
    position: relative;
    width: 40px;
}

.checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
    background-color: #75777A;
    border-radius: 50%;
    content: "";
    height: 16px;
    left: 3px;
    position: absolute;
    top: 4px;
    width: 16px;
}

.checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
    background-color: #E1E2E6;
    transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #40627B;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #fff;
    left: 20px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
}
