* {
    box-sizing: border-box;
}

.fondo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    opacity: 1;
    transition: all 0.35s ease-in;
    z-index: 9;
}

/*
.modal {
    max-width: 350px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 25%;
    left: auto;
    background: rgba(255, 255, 255);
    padding: 0px;
    display: flex;
    place-items: center;
    justify-content: center;
    z-index: 10;
}
*/

.modal {
    position: relative;
    max-width: 400px;
    max-height: 80vh;
    border-radius: 5px;
    background: rgba(255, 255, 255);
    overflow: auto;
    cursor: default;
}

.modal-abort {
    position: relative;
    max-width: 400px;
    max-height: 80vh;
    border-radius: 5px;
    background: var(--white);
    overflow: auto;
    cursor: default;
    z-index: 10;
}

/*
.modal-abort {
    max-width: 350px;
    width: 100%;
    text-align: center;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: rgba(255, 255, 255);
    padding: 0;
    display: flex;
    place-items: center;
    justify-content: center;
    z-index: 10;
}*/

.modal-contenido {
    width: 100%;
    background: #fff;
    position: relative;
    border-radius: 5px;
    box-shadow: rgba(25, 25, 34, 0.2) 0px 7px 29px 0px;
    padding: 20px
}

.contenido {
    width: 100%;
    height: auto;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.modal-header h3 {
    font-weight: 500;
    font-size: 14px;
}

.contenido h5 {
    font-size: 12px;
    margin-top: 70px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #1B1C1E;
}

.abort-title {
    font-weight: 500;
    font-size: 12px;
    margin-top: 70px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #1B1C1E;
}

.modal-button {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 20px;
    height: 20px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
}

.modal-button:hover {
    background: #f2f2f2;
}

.modal-item {
    font-size: .80em;
    font-weight: 300;
    margin-bottom: 15px;
    text-align: justify;
    font-family: 'Roboto';
}

@media (max-width: 575.98px) {

    * {
        box-sizing: border-box;
    }

    .contenido h5 {
        margin-top: 150px;
    }

    .modal-abort {
        max-width: 90%;
        width: 100%;
        text-align: center;
        height: auto;
        position: grid;

        background: rgba(255, 255, 255);
        padding: 0;
        display: fixed;
        place-items: center;
        justify-content: center;
        z-index: 10;
    }


    .modal {
        max-width: 80%;
        width: 100%;
        height: auto;
        position: fixed;
        top: 25%;
        left: 10%;
        background: rgba(255, 255, 255);
        padding: 0px;
        display: flex;
        place-items: center;
        justify-content: center;

    }
}


@media (max-width: 440px) {

    * {
        box-sizing: border-box;
    }

    .modal {
        max-width: 90%;
        width: 100%;
        height: auto;
        position: fixed;
        top: 25%;
        left: 5%;
        background: rgba(255, 255, 255);
        padding: 0px;
        display: flex;
        place-items: center;
        justify-content: center;

    }
}