.content {
    width: 400px;
    height: auto;
    text-align: center;
    padding: 12px 35px 20px 35px;
    position: inherit;
    margin: 0 auto;
    margin-top: 0px;
    justify-items: center;
    border-radius: 50px 50px 0px 0px;
    background: #fff;
}

.content-qr {
    width: 400px;
    height: auto;
    text-align: center;
    padding: 12px 35px 20px 35px;
    position: inherit;
    margin: 0 auto;
    margin-top: 100px;
    justify-items: center;

}

@media (max-width: 575.98px) {

    * {
        box-sizing: border-box;
    }

    form {
        width: 100%;
    }

    .content {
        width: 100%;
        padding: 12px 15px 20px 15px;
    }

    .content-qr {
        width: 100%;
        padding: 12px 15px 20px 15px;
    }
}